'use client';

export default function IconClose(props: any) {
    return (
        <svg {...props} width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" className="editor-shopify-card-win-close">
            <g>
                <path id="Vector 1" d="M1 1L21 21" stroke="#757575"></path>
                <path id="Vector 2" d="M21 1L1 21" stroke="#757575"></path>
            </g>
        </svg>
    );
}
