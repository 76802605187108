'use client';
import { useEffect, useState, Fragment, useRef } from 'react';
import ReactDOM from 'react-dom';
import EditorImg from 'lowcode/components/EditorImg';
import editorEventsBus from 'lowcode/utils/editorEventsBus';
import IconCart from './IconCart';
import IconClose from './IconClose';
import { textLang } from './textLang';
import { getIndexItemForAdd, getTotalPrice } from './utils';
import { formatPrice } from './const';
import getLocal from '@/app/utils/getLocal';
import gtm from '@/utils/gtm';

export default function ShopifyCart() {
    const [openWin, setOpenWin] = useState(false);
    const [items, setItems] = useState([]);
    const refItems = useRef([]); //不使用这个的话,editorEventsBus事件里面的 items 不变化
    const [isClient, setIsClient] = useState(false);
    const { locale } = getLocal();
    const textA = textLang[locale] || textLang['us'];
    const totalObj = getTotalPrice(items);

    useEffect(() => {
        // 右侧切换子元素
        editorEventsBus.on('ShopifyCart-add', (item: any) => {
            const itemsA = refItems.current || [];
            let nextNumber = 1;
            if (item) {
                const indexA = getIndexItemForAdd(itemsA, item);
                const itemsB = JSON.parse(JSON.stringify(itemsA));
                if (indexA > -1) {
                    const number = itemsB[indexA].number || 1;
                    itemsB[indexA].number = number + 1;
                    nextNumber = itemsB[indexA].number;
                } else {
                    itemsB.push({
                        ...item,
                        number: 1,
                    });
                }
                setItems(itemsB);
                refItems.current = itemsB;
                setOpenWin(true);
                setIsClient(true);
            }
        });
    }, []);

    // 执行变化
    const changeCallback = (index: number, val: string | number) => {
        const itemsB = JSON.parse(JSON.stringify(items));
        itemsB[index].number = val;
        setItems(itemsB);
        refItems.current = itemsB;
    };
    // 事件-输入值
    const changeHandle = (index: number, e: any, data: any) => {
        const val = e.target.value;

        changeCallback(index, parseInt(val));
    };
    // 事件-加1
    const addHandle = (index: number, data: any) => {
        const item: any = items[index];
        const number = item.number + 1;
        changeCallback(index, item.number + 1);
        try {
            gtm.push({
                event: 'uaEvent',
                event_name: 'bundle_add_to_cart',
                product_name: data?.title,
                price: data?.price,
                quantity: number,
                button_name: '+',
                list_name: 'none',
                tag: 'none',
            });
        } catch (e) {}
    };
    // 事件-减1
    const redHandle = (index: number, data: any) => {
        const item: any = items[index];
        const number = item.number - 1;
        if (number === 0) {
            const itemsB = JSON.parse(JSON.stringify(items));
            itemsB.splice(index, 1);
            setItems(itemsB);
            refItems.current = itemsB;
        } else {
            changeCallback(index, number);
        }

        try {
            gtm.push({
                event: 'uaEvent',
                event_name: 'bundle_remove_from_cart',
                product_name: data?.title,
                price: data?.price,
                quantity: number,
                button_name: '-',
                list_name: 'none',
                tag: 'none',
            });
        } catch (e) {}
    };
    // 事件-关闭
    const closeHandle = () => {
        setOpenWin(false);
    };
    // 事件-打开
    const openHandle = () => {
        setOpenWin(true);
    };
    // 去到结算
    const checkoutHandle = () => {
        let searchSrc = '';
        const product_name: string[] = [];
        items.forEach((item: any) => {
            searchSrc += `,${item.variantId}:${item.number}`;
            product_name.push(item.title);
        });
        searchSrc = searchSrc.slice(1);

        try {
            gtm.push({
                event: 'uaEvent',
                event_name: 'checkout',
                button_name: `${textA.win_checkout}_${product_name.join('_')}`,
            });
        } catch (e) {
            console.error(e);
        } finally {
            window.open(`https://${locale}.ecoflow.com/cart/${searchSrc}`, '_blank');
        }
    };
    if (!isClient) {
        return null;
    } else {
        return ReactDOM.createPortal(
            <Fragment>
                <div className="editor-shopify-card-right-mod" onClick={openHandle}>
                    <IconCart />
                    {!!totalObj.totalNum && <span className="editor-shopify-card-right-num">{totalObj.totalNum}</span>}
                </div>
                {openWin && (
                    <div className="editor-shopify-card-win">
                        <IconClose onClick={closeHandle} />
                        <div className="editor-shopify-card-win-header">{textA.win_title}</div>
                        <div className="editor-shopify-card-win-mainer">
                            {items.map((item: any, index: number) => {
                                return (
                                    <div className="editor-shopify-card-win-item" key={index}>
                                        <div className="editor-shopify-card-win-item-good">
                                            <div className="editor-shopify-card-win-item-l">
                                                <EditorImg pc={item.imageUrl} />
                                            </div>
                                            <div className="editor-shopify-card-win-item-r">
                                                <div className="editor-shopify-card-win-item-title">{item.title}</div>
                                            </div>
                                        </div>
                                        <div className="editor-shopify-card-win-item-bottom">
                                            <div className="editor-shopify-card-win-item-l shopify-opera-mod">
                                                <div
                                                    className="shopify-opera-mod-btn shopify-opera-mod-reduce"
                                                    onClick={() => {
                                                        redHandle(index, item);
                                                    }}
                                                >
                                                    -
                                                </div>
                                                <input
                                                    type="text"
                                                    className="shopify-opera-mod-number"
                                                    value={item.number}
                                                    onChange={(e: any) => {
                                                        changeHandle(index, e, item);
                                                    }}
                                                />
                                                <div
                                                    className="shopify-opera-mod-btn shopify-opera-mod-add"
                                                    onClick={() => {
                                                        addHandle(index, item);
                                                    }}
                                                >
                                                    +
                                                </div>
                                            </div>
                                            <div className="editor-shopify-card-win-item-r">{formatPrice(item.memberPrice || item.price, locale)}</div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="editor-shopify-card-win-footer">
                            <div className="editor-shopify-card-win-header-price">
                                <span>{textA.win_subtotal}</span>
                                <span>{formatPrice(totalObj.totalPrice, locale)}</span>
                            </div>
                            <div className="editor-shopify-card-win-header-checkout" onClick={checkoutHandle}>
                                {textA.win_checkout}
                            </div>
                        </div>
                    </div>
                )}
            </Fragment>,
            document.body,
        );
    }
}
