import 'lowcode/common/css/global.scss';
import ShopifyCart from 'lowcode/common/ShopifyCart';
import Empty from 'lowcode/utils/Empty';
import { Fragment } from 'react';

import * as CodeTemp from 'lowcode/plugins/indexS';
import * as CodeTemp2 from 'lowcode/plugins2/indexS';
//import * as Lowcomp from 'lowcomp/plugins/indexS';
import ABTestClient from 'lowcode/common/ABTestClient';
import RedirectUrl from 'lowcode/common/RedirectUrl';

const defaultTemp = {
    //...Lowcomp,
    ...CodeTemp2,
    ...CodeTemp, //常用的务必在后面
};

export default function Product({ allTemp = {}, data = {}, className }: any) {
    const { ABTest, thePage = {}, theTemp = [], theData = {}, theAttr = {}, sqlData = {} } = data;
    const theme = thePage.theme || 'white'; //主题色
    const className2 = className ? ` ${className}` : '';
    return (
        <div className={`editor-product ${`editor-${theme}`}${className2}`}>
            {theTemp?.map((item: any) => {
                const id = item.id;
                const name = item.name;
                const ContentA = (defaultTemp as any)[name] || allTemp[name] || Empty;
                // 设置一层隐藏功能
                if (item.__hide) {
                    return null;
                }
                return (
                    <Fragment key={id}>
                        <ContentA lowCodeType="preview" id={id} data={theData[id] || {}} attr={theAttr[id] || {}} theme={theme} thePage={thePage} />
                    </Fragment>
                );
            })}
            {thePage.pageCss && <style>{thePage.pageCss}</style>}
            <ABTestClient ABTest={ABTest} />
            <ShopifyCart />
            <RedirectUrl sqlData={sqlData || {}} />
        </div>
    );
}
