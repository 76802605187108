'use client';

export const getTotalPrice = (items: any, priceName = 'price') => {
    let totalPrice = 0;
    let totalNum = 0;
    items.forEach((item: any) => {
        totalPrice += (item.memberPrice || item[priceName] || 0) * 100 * item.number;
        totalNum += item.number;
    });

    return {
        totalPrice: totalPrice / 100,
        totalNum,
    } as any;
};

export const getIndexItemForAdd = (items: any, item: any) => {
    let currentIndex = -1;
    items.forEach((data: any, index: number) => {
        if (item.variantId === data.variantId) {
            currentIndex = index;
        }
    });

    return currentIndex;
};
