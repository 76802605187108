'use client';
import { useEffect } from 'react';
import { getCookie, setCookie } from './ABTestCookie';

export default function ABTestClient({ ABTest = {} }: any) {
    const { isABTest, ABTestKey, ABTestVal } = ABTest;

    useEffect(() => {
        if (isABTest === 'Y' && ABTestKey && ABTestVal) {
            const nowVal = getCookie(ABTestKey);
            // 不已存在
            if (!nowVal) {
                const hostname = location.hostname || 'https://www.ecoflow.com';
                const pathname = location.pathname || ABTestKey || '';
                const pathArr = pathname.split('/');
                pathArr.splice(1, 1);
                const pathname2 = pathArr.join('/');
                setCookie(ABTestKey, ABTestVal, hostname, pathname);
                if (!pathname2) {
                    //首页
                    setCookie('page_version_index', ABTestVal, hostname, pathname);
                } else {
                    //非首页
                    setCookie('page_version', ABTestVal, hostname, pathname);
                }
            }
        }
    }, []);

    return null;
}
